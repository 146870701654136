require(tplCfgDir + "js/owl.carousel.min.js");
//require(tplCfgDir + "js/masonry.min.js")($);
require(tplCfgDir + "js/scripts.js");
require(defaultTpl + 'js/loadImages.js');

require(tplCfgDir + "css/font-awesome.css");
require(tplCfgDir + "css/owl.carousel.css");
require(tplCfgDir + "css/style.css");
require(tplCfgDir + "css/widgets.css");
require(tplCfgDir + "css/engine.css");


