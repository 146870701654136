/**
 * Created on 16/01/2017.
 */
(function ($) {

	'use strict';

	$(document).ready(function () {



		$('.tabs').each(function(ind, el){
			var $tabsMenu = $(el).find('ul li');
			$tabsMenu.click(function(){
				$tabsMenu.removeClass('active');
				$(this).addClass('active');
				$(el).find('.tabsContent > div').hide();
				$(el).find('.tabsContent > div').eq($(this).index()).show();
			});
			$(el).find('.tabsContent > div').hide();
			$(el).find('.tabsContent > div').eq(0).show();
		});

		/* Menu */
		var $menuSmall = $('.navigation-small');

		$('.menu-expand', $menuSmall).on('click', function (event) {

			event.preventDefault();
			event.stopPropagation();

			var $menuItem = $(event.target).closest('.menu-item-has-children');

			$menuItem.toggleClass('active');
			$('> .sub-menu', $menuItem).slideToggle(400);
		});

		$('#featured-slider').owlCarousel({
			items            : 8,
			itemsDesktop     : [1199, 6],
			itemsDesktopSmall: [979, 5],
			itemsTablet      : [600, 4],
			navigation       : false,
			navigationText   : ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
			pagination       : false
		});

		$('.post-media-slider').owlCarousel({
			items            : 2,
			navigation       : false,
			navigationText   : ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
			pagination       : false
		});

		$('.instagram-feeds').owlCarousel({
			autoPlay         : true,
			items            : 6,
			itemsDesktop     : [1199, 6],
			itemsDesktopSmall: [979, 4],
			itemsTablet      : [768, 3],
			itemsMobile      : [479, 2],
			navigation       : false,
			pagination       : false,
			slideSpeed       : 800,
			navigationText   : ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
			addClassActive   : true
		});

		var $backToTop = $('.btn-back-to-top');

		$backToTop.on('click', function () {
			$('html,body').animate({
				scrollTop: 0
			}, 500);
		});
		$('.back-to-top').on('click', function () {
			$backToTop.trigger('click');
		});


		var $btnSidebar = $('.header-left'),
			$headerRight = $('.header-right');

		/* Active Sidebar Left */
		$btnSidebar.on('click', function () {
			$('body').toggleClass('sidebar-left-active');
		});

		$('.overlay-body').on('click', function () {
			$btnSidebar.trigger('click');
		});

		/* Search Box */
		$('.kd-btn-search', $headerRight).on('click', function (event) {

			event.preventDefault();

			$(this).toggleClass('active');

			$('.box-search').toggleClass('active');
		});

		$(window).on('scroll', function () {
			var wh = $(window).height(),
				scroll = $(window).scrollTop();

			if (scroll > (wh * 0.4)) {
				$backToTop.addClass('active');
			}
			else {
				$backToTop.removeClass('active');
			}
		});



        $('.sub > a').click(function(){
            $('.sub ul').slideUp();
            if ($(this).next().is(":visible")){
                $(this).next().slideUp();
            } else {
                $(this).next().slideToggle();
            }
            return false;
        });
        $('.mini-menu > ul > li > a').click(function(){
            $('.mini-menu > ul > li > a, .sub a').removeClass('active');
            $(this).addClass('active');
        });
		$('.sub ul li a').click(function(){
			$('.sub ul li a').removeClass('active');
			$(this).addClass('active');
		});



    });




})(jQuery);